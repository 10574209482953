import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Editor } from "@tinymce/tinymce-react";

const EditPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state;
  const [city, setCity] = useState(item.city);
  const [article, setArticle] = useState(item.article);

  const handleSave = () => {
    axios
      .put(`https://api.gcdassociation.org/api/edit/${item._id}`, {
        article: article,
      })
      .then((response) => {
        console.log("Data updated successfully:", response.data);
        navigate(-1); // Go back to the previous page
      })
      .catch((error) => {
        console.error("Error updating data: ", error);
      });
  };

  return (
    <Container maxWidth="md">
      <Box p={3} style={{ paddingLeft: "200px", paddingRight: "50px" }}>
        <Typography variant="h4" mb={3}>
          Edit City and Article
        </Typography>
        <Box mb={3}>
          <Typography variant="h6">City</Typography>
          <Editor
            initialValue={city}
            apiKey="6jno9e1u92gi3eswrikeiv0b04vqbhq348s0oyhhm4k1m75i"
            init={{
              height: 150,
              width: "100%",
              plugins: "link image code",
              toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
              menubar: false,
              automatic_uploads: true, // Enable automatic uploads
              auto_focus: true, // Focus the editor automatically
            }}
            // onEditorChange={(content) => setCity(content)}
          />
        </Box>
        <Box mb={3}>
          <Typography variant="h6">Article</Typography>
          <Editor
            apiKey="6jno9e1u92gi3eswrikeiv0b04vqbhq348s0oyhhm4k1m75i"
            initialValue={article}
            init={{
              height: 500,
              width: "100%",
              plugins: "link image code",
              toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
              menubar: false,
              automatic_uploads: true, // Enable automatic uploads
              auto_focus: true, // Focus the editor automatically
              setup: function (editor) {
                editor.on("Change", function (e) {
                  // Use 'Change' event to track changes
                  setArticle(editor.getContent());
                });
              },
            }}
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ fontSize: "1.2rem" }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EditPage;
