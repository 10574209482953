import React from "react";
import Dashboard from "layouts/dashboard";
import Citys from "layouts/citys";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import DataTable from "layouts/citys/data/authorsTableData"; // Adjust the import path as needed
import EditPage from "layouts/citys/data/EditPage"; // Adjust the import path as needed
import InsertData from "layouts/citys/data/insertdata"; // Adjust the import path as needed
import EditHomePAge from "layouts/citys/data/EditHomePage"; // Adjust the import path as needed
import EditAboutPage from "layouts/citys/data/EditAboutPage"; // Adjust the import path as needed
import ContectPage from "layouts/citys/data/ContectPage"; // Adjust the import path as needed

// @mui icons
import Icon from "@mui/material/Icon";
import ProtectedRoute from "layouts/authentication/sign-in/ProtectedRoute"; // Ensure this path is correct

const routes = [
  {
    type: "collapse",
    name: "City",
    key: "city",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/citys",
    component: (
      <ProtectedRoute>
        <Citys />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "InsertData",
    key: "InsertData",
    icon: <Icon fontSize="small">InsertData</Icon>,
    route: "/InsertData",
    component: (
      <ProtectedRoute>
        <InsertData />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "EditHomePAge",
    key: "EditHomePAge",
    icon: <Icon fontSize="small">EditHomePAge</Icon>,
    route: "/EditHomePAge",
    component: (
      <ProtectedRoute>
        <EditHomePAge />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "EditAboutPage",
    key: "EditAboutPage",
    icon: <Icon fontSize="small">EditAboutPage</Icon>,
    route: "/EditAboutPage",
    component: (
      <ProtectedRoute>
        <EditAboutPage />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "ContectPage",
    key: "ContectPage",
    icon: <Icon fontSize="small">ContectPage</Icon>,
    route: "/ContectPage",
    component: (
      <ProtectedRoute>
        <ContectPage />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    route: "/edit/:id",
    component: (
      <ProtectedRoute>
        <EditPage />
      </ProtectedRoute>
    ),
  },
];

export default routes;
