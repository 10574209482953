import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: 24,
  padding: theme.spacing(2, 4, 3),
}));

const MessageBox = styled(Box)({
  border: "1px solid #ccc",
  padding: "10px",
  marginBottom: "20px",
});

const ContectPage = () => {
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    axios
      .get("https://api.gcdassociation.org/api/getmsg")
      .then((response) => {
        setMessages(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="md">
      <Box p={3} style={{ paddingLeft: "200px", paddingRight: "50px" }}>
        <Typography variant="h4" mb={3}>
          Messages
        </Typography>
        {messages.map((message, index) => (
          <MessageBox key={index}>
            <Typography variant="h6">Message from: {message.name}</Typography>
            <Typography variant="body1">Phone: {message.phone}</Typography>
            <Typography variant="body1">Email: {message.email}</Typography>
            <Typography variant="body1">Message: {message.message}</Typography>
            <Typography variant="body1">
              Date: {new Date(message.createdAt).toLocaleDateString()}
            </Typography>
          </MessageBox>
        ))}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalBox>
            <Typography variant="h6" id="simple-modal-title">
              {modalMessage}
            </Typography>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </ModalBox>
        </Modal>
      </Box>
    </Container>
  );
};

export default ContectPage;
