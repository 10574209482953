import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Button from "@mui/material/Button";



const ReadMore = ({ children }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <MDTypography variant="body2" style={{ fontSize: "16px" }}>
      <div dangerouslySetInnerHTML={createMarkup(isReadMore ? children.slice(0, 100) + (children.length > 100 ? '...' : '') : children)} />
      {children.length > 100 && (
        <span
          onClick={toggleReadMore}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {isReadMore ? " read more" : " show less"}
        </span>
      )}
    </MDTypography>
  );
};

ReadMore.propTypes = {
  children: PropTypes.string.isRequired,
};

const CityName = ({ image, name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        <span dangerouslySetInnerHTML={{ __html: name }} />
      </MDTypography>
    </MDBox>
  </MDBox>
);


CityName.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const Article = ({ description }) => {
  return (
    <MDBox
      lineHeight={1}
      textAlign="left"
      style={{
        whiteSpace: "normal",
        wordWrap: "break-word",
        maxWidth: "600px", // Adjust the max width as per your requirement
        padding: "8px",
        fontSize: "20px"
      }}
    >
      <ReadMore>{description}</ReadMore>
    </MDBox>
  );
};

Article.propTypes = {
  description: PropTypes.string.isRequired,
};

export default function DataTable() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const handleEdit = (item) => {
    navigate(`/edit/${item._id}`, { state: { item } });
  };

  useEffect(() => {
    axios
      .get("https://api.gcdassociation.org/api")
      .then((response) => {
        const fetchedData = response.data.data;
        console.log("Fetched data:", fetchedData);

        // Check if fetchedData is an array
        if (Array.isArray(fetchedData)) {
          const updatedRows = fetchedData.map((item) => ({
            cityname: <CityName image={item.url} name={item.city} />,
            article: <Article description={item.article} />,
            edit: (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleEdit(item)}
                style={{ backgroundColor: "yellow" }} // Change the background color here
              >
                Edit
              </Button>
            ),
          }));

          setRows(updatedRows);
        } else {
          console.error("Fetched data is not an array:", fetchedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  return {
    columns: [
      { Header: "CityName", accessor: "cityname", width: "20%", align: "left" },
      { Header: "Article", accessor: "article", width: "60%", align: "left" },
      { Header: "Actions", accessor: "edit", width: "20%", align: "center" },
    ],
    rows,
  };
}
