import React, { useState, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const InsertData = () => {
  const [formatting, setFormatting] = useState([]);
  const [textColor, setTextColor] = useState("black");
  const [apiResponse, setApiResponse] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const cityRef = useRef(null);
  const articleRef = useRef(null);

  const cleanHTML = (html) => {
    return html.replace(/<div><\/div>$/, ""); // Remove trailing empty div
  };

  const handleSave = () => {
    const updatedCity = cleanHTML(cityRef.current.innerHTML);
    const updatedArticle = cleanHTML(articleRef.current.innerHTML);
    axios
      .post(`https://api.gcdassociation.org/api/createarticle`, {
        city: updatedCity,
        article: updatedArticle,
      })
      .then((response) => {
        setApiResponse(response.data); // Assuming response.data is a boolean
        setOpenDialog(true);
      })
      .catch((error) => {
        console.error("Error updating data: ", error);
      });
  };

  const handleFormatChange = (event, newFormats) => {
    setFormatting(newFormats);
    newFormats.forEach((format) => {
      if (format === "bold") {
        document.execCommand("bold", false, null);
      } else if (format === "underline") {
        document.execCommand("underline", false, null);
      } else if (format === "italic") {
        document.execCommand("italic", false, null);
      }
    });
  };

  const handleColorChange = (color) => {
    document.execCommand("foreColor", false, color);
    setTextColor(color);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="md">
      <Box p={3} style={{ paddingLeft: "200px", paddingRight: "50px" }}>
        <Typography variant="h4" mb={3}>
          Insert City and Article
        </Typography>
        <div
          ref={cityRef}
          contentEditable
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            marginBottom: "20px",
            fontSize: "1.2rem",
          }}
        />
        <Toolbar>
          <ToggleButtonGroup
            value={formatting}
            onChange={handleFormatChange}
            aria-label="text formatting"
          >
            <ToggleButton value="bold">Bold</ToggleButton>
            <ToggleButton value="underline">Underline</ToggleButton>
            <ToggleButton value="italic">Italic</ToggleButton>
          </ToggleButtonGroup>
          <Button
            variant="contained"
            onClick={() => handleColorChange("red")}
            style={{ marginLeft: "10px" }}
          >
            Red
          </Button>
          <Button
            variant="contained"
            onClick={() => handleColorChange("blue")}
            style={{ marginLeft: "10px" }}
          >
            Blue
          </Button>
        </Toolbar>
        <div
          ref={articleRef}
          contentEditable
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            marginBottom: "20px",
            minHeight: "200px",
            fontSize: "1.2rem",
          }}
        />
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ fontSize: "1.2rem" }}
          >
            Save
          </Button>
        </Box>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>API Response</DialogTitle>
          <DialogContent>
            <Typography>
              {apiResponse ? "Data saved successfully" : "Failed to save data"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default InsertData;
