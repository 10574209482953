import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';

const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: 24,
  padding: theme.spacing(2, 4, 3),
}));

const EditAboutPage = () => {
  const [formatting, setFormatting] = useState([]);
  const [textColor, setTextColor] = useState("black");
  const [titles, setTitles] = useState({});
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const titleRefs = useRef({});

  useEffect(() => {
    axios
      .get("https://api.gcdassociation.org/api/getuidata/about")
      .then((response) => {
        setTitles(response.data.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const cleanHTML = (html) => {
    return html.replace(/<div><\/div>$/, ""); // Remove trailing empty div
  };

  const handleSave = () => {
    const updatedTitles = Object.keys(titles).reduce((acc, key) => {
      acc[key] = cleanHTML(titleRefs.current[key].innerHTML);
      return acc;
    }, {});

    axios
      .post("https://api.gcdassociation.org/api/editaboutPage", updatedTitles)
      .then((response) => {
        setModalMessage("Data updated successfully!");
        setOpen(true);
      })
      .catch((error) => {
        setModalMessage("Error updating data!");
        setOpen(true);
        console.error("Error updating data: ", error);
      });
  };

  const handleFormatChange = (event, newFormats) => {
    setFormatting(newFormats);
    newFormats.forEach((format) => {
      if (format === "bold") {
        document.execCommand("bold", false, null);
      } else if (format === "underline") {
        document.execCommand("underline", false, null);
      } else if (format === "italic") {
        document.execCommand("italic", false, null);
      }
    });
  };

  const handleColorChange = (color) => {
    document.execCommand("foreColor", false, color);
    setTextColor(color);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="md">
      <Box p={3} style={{ paddingLeft: "200px", paddingRight: "50px" }}>
        <Typography variant="h4" mb={3}>
          Edit Titles
        </Typography>
        {Object.keys(titles).map((key) => (
          <div key={key}>
            <Typography variant="h6">{key}</Typography>
            <div
              ref={(el) => (titleRefs.current[key] = el)}
              contentEditable
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                marginBottom: "20px",
                fontSize: "1.2rem",
              }}
              dangerouslySetInnerHTML={{ __html: titles[key] }}
            />
          </div>
        ))}
        <Toolbar>
          {/* <ToggleButtonGroup
            value={formatting}
            onChange={handleFormatChange}
            aria-label="text formatting"
          >
            <ToggleButton value="bold">Bold</ToggleButton>
            <ToggleButton value="underline">Underline</ToggleButton>
            <ToggleButton value="italic">Italic</ToggleButton>
          </ToggleButtonGroup> */}
          {/* <Button
            variant="contained"
            onClick={() => handleColorChange("red")}
            style={{ marginLeft: "10px" }}
          >
            Red
          </Button>
          <Button
            variant="contained"
            onClick={() => handleColorChange("blue")}
            style={{ marginLeft: "10px" }}
          >
            Blue
          </Button> */}
        </Toolbar>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ fontSize: "1.2rem" }}
          >
            Save
          </Button>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalBox>
            <Typography variant="h6" id="simple-modal-title">
              {modalMessage}
            </Typography>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </ModalBox>
        </Modal>
      </Box>
    </Container>
  );
};

export default EditAboutPage;
